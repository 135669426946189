<template>
  <v-scale-transition>
    <panel
      :title="title"
    >
      <v-date-picker
        v-model="date"
        full-width
        landscape
        min="2020-11-01"
        class="mt-3"
        header-color="indigo lighten-1"
        color="orange"
      />
      <v-toolbar flat>
        <v-spacer />
        <v-autocomplete
          v-if="xforceAdmin"
          v-model="location"
          :items="locations"
          label="Select or type location"
          item-text="location"
          item-value="code"
          prepend-icon="mdi-city"
          return-object
        >
          <template #item="data">
            <v-list-tile-content>
              <v-list-tile-title>{{ data.item.location }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ data.item.leadDealer }}, {{ data.item.pic }}, {{ data.item.contact }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dealers"
        item-key="dealer"
        class="elevation-1"
      >
        <template #items="props">
          <tr
            :active="props.selected"
            @click="checkIn(props)"
          >
            <td class="text-xs-left">
              <v-checkbox
                v-if="canSchedule"
                :input-value="props.selected"
                primary
                hide-details
              />
              <template v-else>
                <v-icon
                  v-if="props.item.selected === 'Y'"
                  color="lime"
                >
                  mdi-checkbox-marked-outline
                </v-icon>
                <v-icon
                  v-else
                  color="grey"
                >
                  mdi-checkbox-blank-outline
                </v-icon>
              </template>
            </td>
            <td class="text-xs-left">
              {{ props.item.dealer }}
            </td>
            <td class="text-xs-left">
              {{ props.item.name }}
            </td>
            <td class="text-xs-left">
              {{ props.item.rank }}
            </td>
            <td class="text-xs-center">
              <v-chip
                :color="props.item.workingType === 'Full-time' ? 'success' : 'success darken-3'"
                text-color="white"
              >
                {{ props.item.workingType }}
              </v-chip>
            </td>
            <td class="text-xs-center">
              <v-chip
                :color="props.item.status === 'Active' ? 'success' : (props.item.status === 'Demoted' ? 'warning' : 'error')"
                text-color="white"
              >
                {{ props.item.status }}
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams, createPostParams } from '@/rest'
import security from '@/security'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      date: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      title: 'Scheduling',
      location: null,
      locations: [],
      snackbar: false,
      snackbarText: null,
      snackbarColor: null,
      selected: [],
      dealers: [],
      headers: [
        {
          text: 'Check-in',
          align: 'left',
          sortable: true,
          value: 'selected',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Rank',
          align: 'left',
          sortable: true,
          value: 'rank',
        },
        {
          text: 'Type',
          align: 'center',
          sortable: true,
          value: 'workingType',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status',
        },
      ],
    }
  },
  computed: {
    xforceAdmin () {
      const properties = security.me.getProperties()
      let admin = false

      properties.forEach(property => {
        if (property.name === 'dashboard.xforce' && property.value === 'admin') {
          admin = true
        }
      })

      return admin
    },
    canSchedule: function () {
      return this.xforceAdmin || this.date >= DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd')
    },
  },
  created: function () {
    !this.xforceAdmin && this.getXForceSchedule(this.date)
    this.xforceAdmin && this.getXForceLocations()
  },
  watch: {
    location: function (location) {
      this.getXForceSchedule(this.date, location.code)
    },
    date: function (date) {
      if (this.xforceAdmin) {
        this.location && this.getXForceSchedule(date, this.location.code)
      } else {
        this.getXForceSchedule(date)
      }
    },
  },
  methods: {
    getXForceSchedule: async function (date, code) {
      this.selected = []
      const params = code ? createGetParams({ date, code }) : createGetParams({ date })
      try {
        const response = await this.$rest.get('getXForceSchedule.php', params)
        this.dealers = response.data
        this.dealers.forEach(dealer => {
          if (dealer.selected === 'Y') {
            this.selected.push(dealer)
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    getXForceLocations: async function () {
      const params = createGetParams({ list: true })
      try {
        const response = await this.$rest.get('getXForceLocations.php', params)
        this.locations = response.data
      } catch (error) {
        console.log(error)
      }
    },
    checkIn: function (dealer) {
      if (!this.canSchedule) return
      dealer.selected = !dealer.selected
      setTimeout(() => {
        const params = createPostParams({
          action: 'checkInXForceDealer',
          payload: {
            entry: this.date,
            dealer: dealer.item.dealer,
            selected: dealer.selected === undefined ? 'N' : 'Y',
            remark: dealer.item.remark,
          },
        })
        this.$rest.post('postAction.php', params)
          .then(response => {
            this.snackbarText = dealer.item.dealer + (dealer.selected === undefined ? ' removed!' : ' assigned!')
            this.snackbarColor = dealer.selected === undefined ? 'warning' : 'success'
            this.snackbar = true
          }).catch(error => {
            let message = error.message
            if (error.response) {
              message += ': ' + error.response.data
            }
            this.snackbarText = message
            this.snackbarColor = 'error'
            this.snackbar = true
            if (dealer.selected === undefined) {
              this.selected.push(dealer.item)
            } else {
              const index = this.selected.indexOf(dealer.item)
              if (index >= 0) {
                this.selected.splice(index, 1)
              }
            }
          })
      }, 300)
    },
  },
}
</script>
